@import "./variables.scss";

body{
  margin: 0;
  font-family: Arial, "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--weiss);
  color: var(--color2);
  font-size: 14px;
}
.clearfix::after{
  content: "";
  clear: both;
  display: table;
}
code{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.logoBox img {
  display: block;
  margin: 0 auto 0 auto;
  clear: both;
}
.row {
  display: flex;
}
@for $col from 1 through 30 {
  .col-#{$col} {
    flex: $col;
  }
}

#root {
  background-image: url("./images/bg-image.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  padding-top: 40px;
  background-size: 100% auto;
}
.fs-container {
  background-color: var(--weiss);
  width: 1024px;
  margin: 0px auto 0 auto;
  padding: 40px 50px;
}
.fs-container.noBackground {
  background-color: transparent;
}

h2 {
  padding-left: 40px;
  color: var(--color2);
  font-size: 36px;
  &.first{
    margin-block-end: 0;
  }
  &.second{
    color: var(--color3);
    margin-block-start: 0;
  }
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.bg-white {
  background: var(--weiss); //var(--grau1);
  border: 1px solid var(--grau5);
}
.bold {
  font-weight: bold;
}
.displayInlineBlock {
  display: inline-block;
}
.navButtons{
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.modalButton,
.navButton{
  cursor: pointer;
  display: inline-block;
  width: 143px;
  height: 45px;
  line-height: 45px;
  color: var(--weiss);
  font-size: 16px;
  text-align: center;
  margin: 20px;
}
.modalButton.agree,
.navButton.next,
.modalButton.notice{
  background: var(--color1);
}
.modalButton.disagree,
.navButton.prev{
  background: var(--grau3);
}
.modalButton.notice {
  margin: 0 auto;
  width: auto;
  padding-left: 15px; padding-right: 15px;
}
.notice-dialog {
  overflow: hidden !important;
  .MuiDialogContent-dividers {
    flex-grow: 0 !important;
    padding-top: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    .notice-header {
      display: flex;
      h2 { flex-grow: 1; margin: 0; padding: 0; }
      button { flex-grow: 0; margin-right: -15px; margin-top: -10px; background: transparent !important; }
      padding-bottom: 10px;
    }
    .MuiDialogContentText-root { margin-bottom: 0 !important; }
  }
}

.button{
  display: inline-block;
}
.addRoomWrapper .header,
.addFloorWrapper .header{
  padding: 10px 0 0 45px;
}
.addRoomWrapper .header span,
.addFloorWrapper .header span{
  padding-left: 5px;
  vertical-align: middle;
}
.addRoomWrapper .button,
.addFloorWrapper .button{
  cursor: pointer;
}
.addRoomWrapper svg.MuiSvgIcon-root,
.addFloorWrapper svg.MuiSvgIcon-root{
  color: var(--color1);
  vertical-align: middle;
  font-size: 19px;
}
.openClose{
  cursor: pointer;
  display: inline-block;
  width: 25px;
  > svg {
    vertical-align: middle;
  }
}
.floorTitle{
  display: inline-block;
}
.floorBoxHeader{
  border-top: 1px solid var(--grau2);
  border-bottom: 1px solid var(--grau2);
}
.floorWrapper{
  margin-top: 20px;
}
.floorRow.sortable-chosen{
  background: var(--grau1);
}
.addProjectListItem.active, .addProjectListItem:hover{
  background: var(--color1);
  color: var(--weiss);
  svg *,
  path,
  polygon,
  g,
  g * {
    fill: var(--weiss);
    stroke: transparent;
  }
}
.addProjectListItem{
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding: 20px 0px;
  background: var(--weiss); //var(--grau1);
  border: 1px solid var(--grau2);
  color: var(--color2);
  max-width: 24%;
  margin-left: 0.5%;
  g,
  g * {
    fill: var(--schwarz);
    stroke: transparent;
  }
}
.roomTitle{
  display: inline-block;
}
.roomRow {
  border-bottom: 1px solid var(--grau2);
}
.roomRow:hover{
  background: var(--grau2);
}
.roomRow.sortable-chosen{
  background: var(--grau1);
}
.secondBlock{
  margin-top: 70px;
  margin-bottom: 35px;
}
.secondBlock h2{
  margin-bottom: 0;
}
.secondBlock span{
  color: var(--color2);
  font-size: 22px;
  padding-left: 40px;
}
.fontSize22{
  font-size: 22px;
  color: var(--color2);
}
.fs-box-white-30-no-margin,
.fs-box-green-30,
.fs-box-30,
.fs-box-white-30{
  padding: 30px;
}
.fs-box-white-30-white{
  padding: 30px;
  margin: 40px 0;
  border: 1px solid var(--grau5);
  &.no-border {
    border: none;
    padding: 20px 13px;
  }
  &.no-margin-top {
    margin-top: 20px;
  }
}
.fs-box-white-30-white-no-padding{

}
.fs-box-white-30-no-margin,
.fs-box-white-30,
.fs-box-white{
  background: var(--weiss); //var(--grau1);
  border: 1px solid var(--grau5);
  &.button {
    margin: 0;
    padding: 0;
    background: transparent;
    width: 100%;
  }
  &.no-margin-top {
    margin-top: 20px;
  }
}
.fs-box-30,
.fs-box-white-30,
.fs-box-white{
  margin: 40px 0;
  border: 1px solid var(--grau5);
  &.no-border {
    border: none;
  }
}
.fs-box-white-structure{
  margin-top: 7%;
}
.fs-box-green-30{
  background: var(--gruen1);
  margin: 40px 0;
}
input.inputTextCode{
  margin-bottom: 20px;
  width: 100%;
  background: var(--grau1);
  color: var(--schwarz);
  font-size: 16px;
  padding: 3px;
  border: 1px solid #ACACAC;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input.inputTextName{
  margin-top: 10px;
  width: 100%;
  background: var(--weiss);
  font-size: 16px;
  padding: 2px;
  border: 1px solid var(--grau5);
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.header-block{
  padding: 40px;
}
.header-block-pn{
  margin-top: 40px;
  padding-top: 3px;
  padding-left: 24px;
  padding-right: 28px;
  padding-bottom: 40px;
  width: 95%;
  height: 80%;
}
.header-block-es{
  margin-top: 40px;
  padding-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 40px;
  width: 95%;
  height: 80%;
}
.header-block-struktur{

  margin-top: 40px;
  padding-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 40px;
  width: 95%;
  height: 80%;
}
.header-item-new-up{
  padding-top: 15px;
  font-size: 18px;
  color: var(--color2);
  font-weight: bold;
}
.header-item-new-down{
  margin-top: -3px;
  font-size: x-large;
  color: var(--color2);
  font-weight: bold;
}
.header-item-new-down-space{
  margin-top: -3px;
  margin-bottom: 45px;
  font-size: x-large;
  color: var(--color2);
  font-weight: bold;
}
.header-item-new-ul {
  padding: 0 15px;
}
.floorWrapper.sortable-chosen{
  background: var(--grau1);
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color: var(--blue) !important;
}
.MuiSwitch-colorSecondary.Mui-checked{
  color: var(--blue) !important;
}
.levelSelectBox{
  border: 1px solid var(--grau5); // var(--color2);
  padding: 15px;
  margin-top: 25px;
  cursor: pointer;
  width: 27%;
}
.levelSelectBox:nth-child(2){
  margin-top: 25px;
  margin-left: 5%;
  margin-right: 5%;
}
.levelSelectBox .description{
  margin: 15px 20px 5px 20px;
}
.levelSelectBox.active,
.levelSelectBox:hover {
  border: 1px solid var(--color1);
  background: var(--color1);
  color: var(--weiss);
}
.levelSelectBox {
  .starsBox, .info-icon {
    .MuiSvgIcon-root {
      color: var(--color2);
    }
  }
  &.active, &:hover {
    .starsBox, .info-icon {
      .MuiSvgIcon-root {
        color: var(--weiss);
      }
    }
  }
}
.equimentSelectComponent{
  padding: 40px 45px;
}
  .equimentBoxHeader{
    cursor: pointer;
    margin-bottom: 15px;
    .openClose {
      font-size: 22px;
      font-weight: bold;
      vertical-align: bottom;
    }
  .equimentBoxHeader-title{
    font-size: 22px;
    font-weight: bold;
    color: var(--color2);
  }
}
.equimentBoxHeader-subtitle{
  padding-left: 25px;
  font-size: 14px;
  color: var(--color2);
}
.equimentBoxHeader-text{
  margin-top: 0; //15px;
  padding-left: 25px;
  font-size: 14px;
  color: var(--color2);
  text-align: left;
  &.bottomLeft {
    text-align: left;
    width: 100%;
    //height: 100%;
    display: flex;
    align-items: flex-end;
    margin-top: 0;
  }
}
.abspos {
  position: relative;
  //.bottomLeft {
  //  position: absolute;
  //  width: 33%;
  //  bottom: 50px;
  //}
}
.tableHeader{
  margin-top: 40px;
}
.optionCounter, .roomCounter {
  color: var(--color2);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.optionName {
  font-size: 12px;
  margin-top: 10px;
}
.labeltext{
  padding-right: 20px;
}
.buttonSelectDesignForRoom{
  cursor: pointer;
  color: var(--color1);
  text-align: left;
  padding-left: 30%;
}
.buttonResetDesignForRoom{
  cursor: pointer;
  color: var(--color1);
  text-align: left;
  padding-left: 30%;
}

.PrivateTabIndicator-colorPrimary-6 {
  background-color: var(--color1)!important;
}

.step5SelectWrapper {
  #grouped-select-room{
    padding-right: 0;
    max-width: 100%;
  }
  .step5SelectItem {
    cursor: pointer;
    border: 1px solid var(--grau5);
    padding: 10px;
    margin: 10px;
    text-align: center;
    &.active{
      color: var(--weiss);
      background-color: var(--color1);
      border-color: var(--grau5);
    }
    :hover :before,
    ::before{
      border: 0 none !important;
    }
    .MuiInput-root {
      .MuiInput-input {
        color: var(--textColor);
      }
      .MuiSelect-icon {
        color: var(--color1);
        font-size: 30px;
        top: 0;
      }
    }
  }
  button{
    margin: 10px;
  }
  button:first-child,
  .step5SelectItem:first-child{
    margin-left: 0;
  }
  button:last-child,
  .step5SelectItem:last-child{
    margin-right: 0;
  }
}
.step5SelectProjectType {
  #grouped-select-room{
    padding-right: 0;
    max-width: 100%;
  }
}
.systemWahlWrapper{
  width: 50%;
  margin: 0 auto;
}

.productList{
  .tableHeader{
    padding: 10px 0 0px 5px;
    border-top: 1px solid var(--grau2);
    font-style: italic;
  }
  .room-container {
    border: 1px solid var(--grau5);
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
  }
  .roomHeader{
    padding: 10px 0 0px 5px;
    font-style: italic;
    font-weight: bold;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .name { flex-grow: 1;}
    .step5SelectItem {
      font-weight: normal;
      font-style: normal;
      cursor: pointer;
      border: 1px solid var(--grau5);
      padding: 10px;
      margin: 10px;
      text-align: center;
      max-width: 25%;
      &.active{
        color: var(--weiss);
        background-color: var(--color1);
        border-color: var(--grau5);
      }
      :hover :before,
      ::before{
        border: 0 none !important;
      }
      .MuiInput-root {
        .MuiInput-input {
          color: var(--color2);
        }
        .MuiSelect-icon {
          color: var(--color2);
        }
      }
    }
  }
  .group{
    margin-bottom: 40px;
  }
  .groupHeader{
    color: var(--color2);
    font-weight: bold;
    font-size: 22px;
    padding: 15px 0;
  }
  .groupSubHeader{
    color: var(--color2);
    font-weight: bold;
    font-size: 14px;
    padding: 15px 0;
    margin: -30px 0 0;
    &.hidden {
      display: none;
    }
  }
  .groupContent > .row{
    padding: 10px 0 10px 5px;
    border-top: 1px solid var(--grau2);
    &:last-child{
      border-bottom: 1px solid var(--grau2);
    }
    &:hover{
      background-color: var(--grau2);
    }
  }
  .groupContent > .row.productSwitchGroup{
    border: 0 none;
  }
  .groupContent .productSwitchRow{
    padding: 10px 0 10px 5px;
    border-top: 1px solid var(--grau2);
    &:last-child{
      margin-bottom: 25px;
    }
    //&:first-child{
    //  border-top: 0 none;
    //}
  }
}
.foerderbox{
  margin-bottom: 0
}
.foerderbox,
.preisbox{
  .row{
    margin-top: 30px;
  }
  .title{
    color: var(--grau3);
    font-weight: bold;
    font-size: 22px;
  }
  .subtitle{
    color: var(--grau3);
    font-size: 12px;
  }
  .gesamtsumme{
    width: 75%;
    max-width: 320px;
    margin-right: 0;
    margin-left: auto;
    margin-top: 15px;
    .label{
      //display: inline-block;
      float: left;
      text-align: left;
    }
    .value{
      //display: inline-block;
      float: right;
      width: 150px;
      text-align: right;
    }
    .line2{
      clear: both;
      text-align: left;
    }
    .label,
    .value{
      color: var(--color2);
      font-weight: bold;
    }
    &.mitBEG{
      .label,
      .value{
        font-size: 22px;
      }
    }
    &.ohneBEG{
      .label,
      .value{
        font-size: 18px;
      }
    }
  }
}
.foerderForm{
  width: 60%;
  margin: 0 auto;
}
.headerMenu{
  float: right;
  a{
    color: var(--color2);
    text-decoration: none;
  }
  a:hover{
    color: var(--color2);
    text-decoration: none;
  }
  .headerMenuItem{
    cursor: pointer;
    margin-right: 20px;
    font-weight: bold;
    color: var(--color2);
    &:first-child{
      margin-right: 40px;
    }
  }
}

.exportButtons{
  margin-top: 20px;
  button{
    margin: 0 5px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.codeBoxFinish{
  font-size: 22px;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  &.button {
    text-align: right;
    margin: -40px 0;
    width: 100%;
    .icon-to-page-bottom {
      &.only-hover { display: none; }
    }
    &:hover {
      .icon-to-page-bottom {
        display: none;
        &.only-hover { display: unset; }
      }
    }
  }
}
.button {
  .icon-to-page-bottom {
    &.only-hover { display: none; }
  }
  &:hover {
    color: var(--color1);
    .icon-to-page-bottom {
      display: none;
      &.only-hover { display: unset; }
    }
  }
  &.woText {
    .MuiButton-label {
      align-items: flex-end;
      flex-direction: column;
      margin-right: -10px;
    }
  }
}
.navigate-back {
  .MuiButton-label {
    align-items: flex-start;
    flex-direction: column;
  }
}
.footer{
  color: var(--grau3);
  a{
    color: var(--grau3);
    text-decoration: none;
    padding: 0 10px;
    &:nth-child(n+2){
      border-left: 1px solid var(--grau3);
    }
  }
  a:hover{
    color: var(--schwarz);
    text-decoration: none;
  }

}
.box {
  width: 46%;
  padding: 15px;
  font-size: 16px;
  transition: all 3s;
  &.right {
    float: right;
  }
  &.blue {
    background: var(--blue);
    color: white;
    width: 45%;
    padding: 20px;
    h1, h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }
  &.darkblue {
    background: var(--darkblue);
    color: white;
    width: 45%;
    padding: 20px;
    h1, h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 25px;
    color: white;
    padding-left: 0;
  }
  p {
    width: 100%;
    a {
      text-align: right;
      display: block;
      cursor: pointer;
    }
  }
  .inputBoxContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      display: flex;
      flex-grow: 0;
      margin-bottom: 0;
      align-content: center;
      align-items: center;
      height: fit-content;
    }
    input {
      flex-grow: 1;
      margin-right: 9.48px;
      padding: 8px 0
    }
  }
  .MuiStepper-root {
    .MuiStep-vertical {
      margin-bottom: 15px;
      .MuiStepConnector-root {
        .MuiStepConnector-line {
          border: none;
        }
      }
      .MuiStepLabel-root {
        display: flex;
        flex-direction: row;
        width: 100%;
        .MuiStepLabel-iconContainer {
          margin-right: 15px;
          .MuiStepIcon-root {
            color: var(--blue);
          }
        }
        .MuiStepLabel-labelContainer {
          .MuiStepLabel-label {
            margin-top: 0;
            color: black;
          }
        }
      }
    }
  }
  &.werbung {
    float: right;
    margin-top: -45px;
    background: var(--startGreen);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &.lightGreen {
    margin-top: 15px;
    width: 97.1%;
    background: var(--startGreenLight);
    li {
      margin-bottom: 5px;
    }
  }
  &.funktionenHeader {
    font-size: 18px;
    padding-top: 130px;
  }
  &.subpage {
    padding: 0;
    width: 100%;
    img {
      width: 100%;
    }
    .page {
      width: 1024px;
      margin: 0px auto 0 auto;
      padding: 40px 50px;
      display: flex;
      flex-direction: row;
      .left, .right {
        width: 50%;
      }
      .left {
        float: left;
        h2 {
          color: var(--subpageH2);
          font-size: 22px;
        }
        p {
          line-height: 22px;
        }
      }
      .right {
        border: 1px solid white;
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 500px;
        margin-top: -155px;
        align-content: center;
      }
    }
  }
}
.MuiTabs-root {
  .MuiTab-textColorPrimary {
    &.Mui-selected {
      color: var(--color1) !important;
    }
  }
  .MuiTabs-indicator {
    background-color: var(--color1) !important;
  }
}
.text-center.col-12 {
  width: 12.5%;
  flex: none;
}
.text-center.col-15 {
  width: 13%;
  flex: none;
}

.button-product-switch{
  display: inline-block;
  padding-top: 5px;
  cursor: pointer;
  .MuiSvgIcon-root{
    vertical-align: bottom;
    width: 0.7em;
    height: 0.7em;
  }
}
.MuiTypography-body1{
  font-size: inherit !important;
}
.system-select-button {
  .active {
    display: none;
  }

  &:hover {
    .active{
      display: inline-block;
    }
    .inactive {
      display: none;
    }
  }
}

.svgDescription {
  font-weight: bold;
  color: var(--color2);
  font-size: 12px;
  margin-top: 10px;
}
.svgDescription-step-two {
  font-weight: bold;
  color: var(--color2);
  font-size: 12px;
  margin-top: 10px;
}
.border{
  margin-top: 2%;
  border: 1px solid #E6E6E6;
  margin-bottom: -1.5%;
}
.noTextTransform {
  text-transform: none;
}
.mouseover-hand {
  cursor: pointer;
  .MuiStepLabel-root.Mui-disabled {
    cursor: inherit;
  }
  &:hover {
    * {
      color: var(--blue) !important;
    }
    .MuiStepIcon-text {
      color: var(--weiss) !important;
    }
  }
}
.stickyRow {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 10px;
  padding-bottom: 10px;
  &.justSticky {
    display: flex;
    align-items: center;
    background: white;
    z-index: 9;
    box-shadow: 0 0 15px rgb(0, 0, 0, 0.25);
    margin: 0 -30px;
    padding: 0 30px;
    height: 0; opacity: 0;
    // transition: 0.3s ease-in-out;
    .heading { font-size: 24px; font-weight: bold; }
    .image-container { width: 50px; height: 50px; margin: 0 5.66% 0 auto; }
    &.isSticky {
      height: unset;
      opacity: 1;
      padding: 15px 30px;
      // transition: 0.3s ease-in-out;
    }
  }
}
.tableStickyHeader {
  position: sticky;
  top: 0;
  background: var(--weiss); // var(--grau1);
  z-index: 9;
  padding-top: 10px;
  padding-bottom: 10px;
  .tableHeader {
    margin-top: 0;
  }
  &.noposition {
    position: unset;
  }
}
.btn_loading {
  border: 10px solid var(--grau1);
  border-top: 10px solid var(--grau3);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.composite-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 15px 0;
  position: relative;
  .compositeImage, .arrow-container, .description { flex-grow: 1; }
  .compositeImage { max-width: 220px; }
  .description {
    padding: 0;
    width: 300px;
    position: absolute;
    bottom: 0; right: 0;
  }
  .arrow-container {
    width: 100%;
    height: 50px;
    position: relative;
    margin-top: 135px;
    text-align: right;
    flex-grow: 1;
    padding: 0 15px;
    &.textLeft { text-align: left; }
    &.button-width { padding: 0 15px; }
    button {
      cursor: pointer;
      border: 1px solid var(--grau5);
      background: transparent;
      font-size: 15px;
      padding: 10px 15px;
      color: var(--grau5);
      &.hidden { opacity: 0; }
      &.button {
        &:hover { border: 1px solid var(--schwarz); color: black; }
      }
      .tooltip {
        display: none;
        position: absolute;
        top: -20px;
        width: 150px;
        left: -10px;
        font-size: 12px;
        color: var(--color2);
      }
      &.arrow-left {
        position: relative;
        .tooltip {
          left: -90px;
        }
      }
      &:hover {
        .tooltip {
          display: unset;
        }
      }
    }
    .arrow {
      border: solid black;
      border-width: 0 5px 5px 0;
      display: inline-block;
      padding: 3px;
      width: 10px;
      height: 10px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 5px;
      &:hover {
        border-color: var(--color1);
      }
    }
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
.MuiStep-horizontal {
  .MuiStepLabel-root {
    .MuiStepLabel-iconContainer {
      .customIcon {
        .MuiStepIcon-root {
          color: var(--color3);

          &.MuiStepIcon-active {
            color: var(--color2);
          }

          &.MuiStepIcon-completed {
            color: var(--color2);
          }

          .MuiStepIcon-text {
            font-size: 0.6em;
            color: var(--weiss);
          }
        }
      }
    }
  }
  .MuiSteoConnector-horizontal {
    top: 1em;
  }
}
.gray-border {
  border: 1px solid var(--grau5);
  padding: 0 30px 15px 30px;
  margin-left: -15px;
  margin-right: -15px;
  &.wMargin {
    margin-bottom: 15px;
    margin-top: -20px;
  }
  &.last-page {
    margin-top: 45px;
    padding-top: 15px;
    margin-bottom: -30px;
    .header-item-new-up {
      padding-top: 0 !important;
      padding-left: 0 !important;
    }
    .fs-box-white-30-white {
      padding: 15px 0;
      margin-bottom: 0;
    }
    .productList {
      padding: 30px 0;
    }
    .fs-box-white-30 {
      &:last-child {
        margin-bottom: 15px;
      }
    }
  }
}
.floorBoxHeader {
  .buttonSelectDesignForRoom {
    position: relative;
    padding-top: 5px;
    font-size: 13px;
    .resetIcon {
      position: absolute;
      left: calc(50% - 105px);
      top: 0px;
    }
  }
  .buttonResetDesignForRoom {
    position: relative;
    font-size: 13px;
    padding-top: 5px;
    .resetIcon {
      position: absolute;
      top: 0;
      left: calc(50% - 115px);
    }
  }
}
.roomRow {
  .buttonSelectDesignForRoom {
    position: relative;
    padding-top: 5px;
    font-size: 13px;
    .resetIcon {
      position: absolute;
      left: calc(50% - 105px);
      top: 12px;
    }
  }
  .buttonResetDesignForRoom {
    position: relative;
    font-size: 13px;
    .resetIcon {
      position: absolute;
      top: 6px;
      left: calc(50% - 115px);
    }
  }
}
.copy-link-tooltip {
  width: auto;
}
.inClipboardNotice {
  background: var(--color1);
  color: var(--weiss);
  padding: 15px 25px;
  position: absolute;
  right: -40px;
  top: -6px;
  font-size: 14px;
  border-radius: 3px;
}
.faq-title {
  //font-size: 24px;
  padding-left: 15px;
  color: var(--color2);
}
.btn-close-faq {
  fill: var(--color2);
  float: right;
  margin-right: 7px !important;
  min-width: 36px !important;
  &:hover {
    background: var(--color2) !important;
    fill: var(--weiss) !important;
  }
}
.MuiPopover-paper {
  .MuiMenu-list {
    .productFilterItem, .roomFilterRoomItem {
      font-weight: normal !important;
      color: var(--color2);
    }
  }
}
.icon-to-page-bottom {
  margin-left: 5px;
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 2rem;
}
.design-inline-flex {
  display: flex;
  .left-side, .right-side { width: 50%; display: inline-flex; }
  .left-side { flex-direction: column; }
  .left-side {
    .MuiTabs-flexContainer {
      width: 100%;
      flex-wrap: wrap;
      .MuiTab-root {
        min-width: unset !important;
        padding: 7px 9px;
        &.Mui-selected {
          border-bottom: 2px solid;
        }
      }
    }
    .MuiTabs-indicator {
      border: none;
    }
  }
}
.design-tabs {
  .MuiTabs-flexContainer {
    width: 100%;
    flex-wrap: wrap;
    .MuiTab-root {
      min-width: unset !important;
      padding: 7px 9px;
      &.Mui-selected {
        border-bottom: 2px solid;
      }
    }
  }
  .MuiTabs-indicator {
    border: none;
  }
}
.left-side {
  .MuiTabs-root {
    .MuiTabs-indicator {
      background-color: transparent !important;
    }
  }
}
.design-title {
  margin-bottom: 15px;
}
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 123px !important;
  }
}
.load-project-button-container {
  display: inline-flex;
  float: right;
  margin-top: 28px;
  cursor: pointer;
  &:hover {
    color: var(--color1);
  }
}
.load-project-container {
  display: inline-flex;
  float: right;
  margin-top: 28px;
  background: var(--weiss);
  border: 1px solid var(--grau5);
  flex-direction: column;
  padding: 15px;
  max-width: 45%;
  animation: all ease-in-out 0.3s;
  &.noborder {
    border: 1px solid transparent;
    min-width: 35%; text-align: right;
    padding-top: 0; padding-right: 0;
    margin-top: 27px; margin-right: -1px;
  }
  p {
    margin: 0;
  }
  .openCloseInputbox {
    cursor: pointer;
    margin-bottom: 15px;
    font-weight: bold;
    &:hover {
      color: var(--color1);
    }
  }
  &:after {
    display: block;
    clear: both;
  }
  .inputBoxContainer {
    .inputText {
      margin-bottom: 20px;
      width: 100%;
      background: var(--weiss);
      border: 1px solid var(--grau5);
      color: var(--schwarz);
      font-size: 16px;
      padding: 3px;
      height: 40px;
      box-sizing: border-box;
      text-align: center;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;

    }
  }
}

/* Layout Figma */
#root { background: none; padding-top: 0; }
.header-container {
  width: 100%;
  .logo-container {
    padding: 20px 0;
    text-align: center;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    img { margin: 0 auto; }
    .links-container {
      position: absolute;
      right: 0;
      .headerMenuItem {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
  .banner-container {
    //background: #dfdcdc; //linear-gradient(180deg, #E0E0D8 0%, #DFDCD3 50%, #DDD9CE 100%);
    background: rgb(222, 220, 220);;
    max-height: 264px;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    text-align: right;
    img { object-fit: contain; max-width: 100%; }
    .welcome-container {
      text-align: left;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      padding: 55px 115px;
      max-width: 40%;
      .title {
        color: var(--blue);
        font-size: 36px;
        font-weight: bold;
        &.darkblue { color: var(--darkblue); }
      }
      .text {
        color: var(--textColor);
        font-size: 16px;
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }
}
.project-container, .footer-container {
  width: 1024px;
  margin: 0 auto;
  padding: 40px 50px;
}
.project-container {
  padding-top: 0;
}
.section-container {
  padding: 15px 0;
  color: var(--textColor);
  font-size: 12px;
  &.closed {
    border-bottom: 1px solid var(--borderColor);
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .section-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--darkblue);
  }
  .section-sub-title {
    color: var(--textColor);
    padding: 15px 0 15px;
    a {
      color: var(--darkblue);
      &:hover { color: var(--blue); }
    }
  }
  .section-text {
    color: var(--textColor);
    padding: 0 15px;
    margin: 0;
  }
  .inputTextName {
    padding: 15px 15px;
    height: auto;
    border-width: 1px;
  }
  .section-container-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      align-self: stretch;
      align-items: flex-start;
    }
  }
  .section-radio-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    strong { font-size: 16px; margin-top: 15px;}
    .description {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .addProjectList {
    display: flex;
    flex-direction: row;
    .addProjectListItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-width: 2px;
      max-width: 23%;
      margin: 0 auto;
      padding: 20px 0 0 0;
      color: var(--textColor);
      font-size: 16px;
      font-weight: normal;
      border-color: var(--borderColor);
      &:first-child { margin-left: 0; }
      &:last-child {margin-right: 0; }
      &.active, &:hover { background: transparent; border-color: var(--blue); color: var(--textColor); }
    }
  }
  .labelBox {
    width: 100%;
    display: inline-flex;
    align-items: center;
  }
  .roomRow {
    &:hover { background: transparent; }
  }
  .addRoomWrapper, .addFloorWrapper {
    .header {
      padding: 10px 0;
    }
  }
  .stickyRow {
    background: white;
    z-index: 99;
  }
  .floorList {
    &.step1 {
      .CountBoxButton {
        background: transparent;
        font-size: 24px !important;
        color: var(--color1) !important;
      }

      .col-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .countBoxWrapper {
        padding: 0;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
  .roomList {
    .actions-container {
      display: flex;
      align-items: center;
      .addRoomWrapper { margin-right: auto; }
      .addFloorWrapper { margin-left: auto; }
    }
  }
  .SelectConfLevelComponentWrapper {
    .levelSelectBox {
      border: 2px solid var(--borderColor);
      background: transparent;
      color: var(--textColor);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &:hover, &.active { border-color: var(--blue); }
      .title {
        margin-top: 15px;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .stars-container {
        .blue, .gray {
          width: 40px; height: 40px; margin: 0 8px;
          padding-bottom: 5px;
        }
        .blue { border-bottom: 2px solid var(--blue); }
        .gray { border-bottom: 2px solid var(--textColor); }
      }
      .equipments {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px 10px;
        .equipment {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 15px;
          padding: 0 15px;
          img {
            margin-right: 15px;
          }
        }
      }
    }
  }
  .addFloorWrapper {
    &.right {
      .header {
        text-align: right; display: flex;
        .button {
          display: flex;
          align-items: center;
          margin: 0 0 0 auto;
          flex-grow: 0;
          flex-shrink: 0;
          min-width: 150px;
          img { margin-left: auto; }
          span {margin-right: auto; }
        }
      }
    }
  }
  .equimentBoxHeader {
    position: relative;
    .openClose {
      position: absolute;
      left: -24px; top: -5px;
      color: var(--color1) !important;
    }
    .equimentBoxHeader-title { font-size: 18px; }
  }
  .tableStickyHeader {
    .equimentBoxHeader-text { padding-left: 0; font-weight: 400; color: var(--textColor); }
    .svgDescription-step-two { color: var(--textColor); font-weight: 400; }
    &.closed { border-bottom: 1px solid var(--borderColor); padding-bottom: 30px; }
  }
  .floorBoxHeader {
    position: relative;
    .col-3, .col-1 {
      display: flex; flex-direction: row; align-items: center;
    }
    .col-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .openClose {
      position: absolute;
      left: -24px;
      color: var(--color1);
    }
  }
  .roomRow {
    .col-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .CountBoxWrapper {
    color: var(--color1);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    padding: 0 3px;
    height: 100%;
    .CountBoxButton { background: transparent; }
    .CountBoxValue { color: var(--darkblue); font-size: 16px; font-weight: 400; letter-spacing: -0.002em;}
  }
  .floorWrapper {
    &.closed {
      border-bottom: 1px solid var(--borderColor);
    }
  }
  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: var(--blue);
    }
  }
  .MuiSwitch-root { margin: 0 auto; }
  .closed {
    border-bottom: 1px solid var(--borderColor);
    padding: 15px 0 30px;
  }
  .group {
    padding-bottom: 15px;
    //border-bottom: 1px solid var(--borderColor);
    margin-bottom: 15px;
    .step5 {
      .productSwitchGroup {
        margin-bottom: 30px;
        .productSwitchRow {
          border-top: 1px solid var(--borderColor);
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
  &.step6 {
    .groupContent {
      .row {
        padding: 15px 0;
        border-top: 1px solid var(--borderColor);
        &:last-child { border-bottom: 1px solid var(--borderColor); &.noborder { border: none; }}
        &.noborder { padding: 0; border: none; }
      }
    }
  }
  .system-select-wrapper {
    display: flex; flex-direction: row; align-items: center;
    .system-select-button {
      width: 48%;
      &:first-child { margin-left: 0; }
      &:last-child {margin-right: 0; }
      .MuiButton-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        img { margin-bottom: 15px; }
        .title { margin-bottom: 15px; color: var(--textColor); font-size: 16px; font-weight: 400; }
        .subtitle { color: var(--borderColor); font-size: 16px; font-weight: 400; }
      }
    }
  }
  .openClose {
    &.step6 {
      position: absolute;
      left: -24px; top: 12px;
      color: var(--color1);
    }
  }
  .closed {
    .tableHeader { margin-top: -15px; }
  }
  .codeBoxFinish {
    display: flex; flex-direction: row; align-items: center;
    width: unset;
    margin-top: 45px;
    padding: 15px 0;
    border-top: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
    div { flex-grow: 1; text-align: right; }
    .section-text { flex-grow: 0; text-align: left; padding: 0; font-size: 16px; }
  }
  .MuiCheckbox-root {
    margin: 0 auto;
  }
}
.section-separator {
  height: 0; width: 100%;
  border-bottom: 1px solid var(--borderColor);
  margin: 0 0 15px 0;
}
.foerderungsChecker { &.Mui-checked { color: var(--blue) !important; } }
.stepper-container {
  display: flex; flex-direction: column; align-items: center;
  .MuiStepper-horizontal {
    flex: 0 1 auto !important;
    margin: 84px 0 !important;
    min-width: 1024px;
    max-width: 1024px;
    padding: 0;
    .MuiStep-alternativeLabel {
      flex: 1 1 auto !important;
      margin-bottom: 0;
      &:first-child {
        flex: 0 auto !important;
      }
    }
  }
}
.MuiStep-horizontal {
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 32px;
  flex: 0 1 auto !important;
  margin-bottom: 40px;
  .MuiStepLabel-root {
    &.MuiStepLabel-alternativeLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      .customIcon { max-height: 24px; margin-right: 8px; margin-left: 10px; }
    }
  }
  .MuiStepLabel-alternativeLabel { color: var(--stepColor); }
  .MuiStepIcon-root { color: var(--stepColor) !important; }
  .MuiStepLabel-iconContainer { &.MuiStepLabel-alternativeLabel {display: flex; align-items: center; } }
  .MuiStepLabel-labelContainer { width: unset; display: flex; flex-direction: row; align-items: center; }
  .MuiStepLabel-label { &.MuiStepLabel-alternativeLabel { margin: 0; white-space: nowrap; } }
  .MuiStepIcon-active { color: var(--stepColorActive) !important; }
  .MuiStepIcon-completed { color: var(--stepColorCompleted) !important; }
  .MuiStepIcon-text { font-size: 14px !important; }
  .MuiStepLabel-active, .MuiStepLabel-completed { font-weight: 700 !important; }
}
.footer-container {
  padding-top: 0;
  .footer-actions-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    button {
      margin-left: auto;
      margin-right: auto;
      &.woText {
        position: absolute;
        right: 0;
        margin: 0;
        padding: 0;
        width: 46px; height: 46px;
        .MuiButton-label {
          margin: 0;
          img {
            margin: 0;
          }
        }
      }
    }
  }
}
.design-selection-text {
  //max-width: 160px;
  //margin-left: auto;
  //margin-right: auto;
  color: var(--textColor);
}
.contextBoxHover {
  display: flex;
  flex-direction: row;
  .displayInlineBlock {
    display: inline-flex;
  }
}
.design-column-header {
  position: relative;
  .on-hover {
    opacity: 0;
    position: absolute;
    top: 15px; left: calc(50% + 30px);
    img {
      margin-right: 15px;
      cursor: pointer;
      &:last-child { margin-right: unset; }
    }
  }
  .cancel-edit {
    position: absolute;
    top: 15px; left: calc(50% + 30px);
  }
  &:hover {
    .on-hover {
      opacity: 1;
      &.hidden {
        opacity: 0;
      }
    }
  }
  &.active {
    background: var(--color1);
  }
}
.header-item-new-up {
  min-height: 36px;
  .subtitle {
    font-size: 14px;
    font-weight: normal;
  }
  button {
    margin-top: -6px;
  }
}
.design-block {
  padding: 0;
  max-width: 1024px;
  margin: 0 auto;
  border: none;
}
.floorBoxHeader {
  position: relative;
  .openClose {
    &.orange {
      position: absolute;
      height: 24px;
      left: -30px;

      svg {
        fill: var(--color1);
        font-size: 24px;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }
  }
}
/* End Layout Figma */
.CountBoxWrapper {
  .CountBoxButton {
    margin-left: auto;
    &:last-child {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
@media(max-width: 800px) {
  .header-container {
    width: 1124px;
  }
}
.pb_grayed {
  position: relative;
  .pb_variante {
    position: absolute;
    top: -20px; left: 50px;
    font-style: italic;
  }
  .col-20 {
    background: rgba(0,0,0,0.05);
    padding: 0 15px; margin: 0 -15px;
    .productSwitchRow {
      &:first-child { border-top-color: transparent !important; }
    }
  }
}
