@import "./../../variables.scss";

.editHandle,
.deleteHandle,
.dragHandle {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}

.labelBox {
  padding: 10px 0;
}

.dragActive .labelBox:hover .dragHandle *,
.dragHandle *,
.dragActive .labelBox:hover .deleteHandle *,
.deleteHandle *,
.dragActive .labelBox:hover .editHandle *,
.editHandle * {
  display: none;
  padding: 0 2px;
}

.labelBox:hover .dragHandle *,
.labelBox:hover .editHandle *,
.labelBox:hover .deleteHandle * {
  display: inline-block;
}

.labeltext{
  display: inline-block;
  vertical-align: bottom;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
