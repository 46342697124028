@import "./../../variables.scss";

.CheckBoxWrapper {
  padding: 4px 3px;

  .checkbox {
    background: transparent;
    border: 1px solid var(--grau6);
    position: relative;
    width: 22px;
    height: 22px;
    margin: 6px auto;
    display: block;
    cursor: pointer;

    &.checked {
      background: var(--stepColorActive);
      border-color: var(--stepColorActive);
      cursor: unset;
    }

    input { position: absolute; left: -10000px;}
    img { position: relative; top: -6px; left: 0px; }
    &.header {
      margin: 0 auto;
      img {top: 2px;}
    }
  }
}



