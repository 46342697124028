
:root{
  --color1: #F56400;
  --color2: #02477E;
  --color3: #08A7E1;
  --weiss: #FFFFFF;
  --schwarz: #000000;
  --grau1: #F4F4F4;
  --grau2: #E6E6E6;
  --grau3: #707070;
  --grau4: #4A4A4A;
  --grau5: #bdbdbd;
  --grau6: #AFAFAF;
  --gruen1: #d9ebc6;
  --blue: #00AAE1;
  --darkblue: #00477E;
  --startGreen: #A6CC7F;
  --startGreenLight: #D9EBC6;
  --subpageH2: #02477E;
  --textColor: #4A4A4A;
  --borderColor: #C4C4C4;
  --borderColorButton: #F56400;
  --backgroundColorButtonActive: #F56400;
  --backgroundColorButtonHover: #FF8F33;
  --backgroundColorButton: transparent;
  --paddingButton: "13px 24px";
  --stepColor: #AFAFAF;
  --stepColorActive: var(--blue);
  --stepColorCompleted: var(--blue);
}

$fsContainerBorder: 1px solid red;
$CountBoxButtonRadius: 100%;
